import { useRouter } from '@ui/overrides/next/router';
import React, { useEffect } from 'react';

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    router.replace('/');
  });

  return <div style={{ height: '100vh' }} />;
}
